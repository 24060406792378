exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-bases-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/bases.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-bases-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-best-seller-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/best-seller.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-best-seller-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-elrincondeltano-blog-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/elrincondeltano-blog.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-elrincondeltano-blog-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-john-wick-4-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/john-wick-4.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-john-wick-4-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-king-arthur-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/king-arthur.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-king-arthur-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-movie-scraper-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/movie-scraper.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-movie-scraper-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-samext-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/samext.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-samext-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-samratings-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/samratings.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-samratings-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-team-of-rivals-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/team-of-rivals.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-team-of-rivals-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-tipeo-nada-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/tipeo-nada.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-tipeo-nada-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-youtrmr-web-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/buildhome/repo/src/content/youtrmr-web.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-youtrmr-web-mdx" */)
}

